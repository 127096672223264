// Font: Jura
@import url("https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap");

// Component Styling
@import "styling/_card.scss";
@import "styling/_cards.scss";
@import "styling/_canvas.scss";
@import "styling/_musicplayer.scss";
@import "styling/_navbar.scss";

* {
  font-family: "Jura", sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  background-color: beige;
  color: black;
  ::-webkit-scrollbar {
    display: none;
  }
}

h1 {
  font-weight: 400;
  font-size: 30px;
}

h5 {
  font-size: 14px;
}

p {
  font-size: 12px;
}
