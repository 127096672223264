.navbar {
  &-container {
    position: fixed;
    width: 100%;
    height: 12vh;
    z-index: 100;
    top: 0;
    background-color: beige;
  }
  &-logo {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    img {
      width: auto;
      height: 50px;
    }
    h1 {
      letter-spacing: 3px;
    }
  }
}
