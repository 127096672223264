.cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 70px;
  align-items: center;
  padding: 15vh 50px 15vh 50px;
  margin-top: 40px;
}

.genre-filter {
  background-color: beige;
  width: auto;
  z-index: 999;
  position: fixed;
  top: 12vh;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  gap: 10px;
  cursor: pointer;
  &:hover {
    background-color: black;
    color: beige;
  }
  h4 {
    font-weight: 100;
  }
}

@media only screen and (max-width: 600px) {
  .cards-container {
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0px;
    align-items: flex-start;
    margin-top: 12vh;
    padding-bottom: 8vh;
    border: none;
  }
  .genre-filter {
    position: initial;
    margin-top: -300px;
    margin-bottom: -250px;
    margin-left: 0px;
    width: 150px;
  }
}

@media only screen and (max-width: 1700px) {
  .cards-container {
    justify-content: center !important;
  }
}
