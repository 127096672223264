// Open Canvas button

.open-canvas {
  position: fixed;
  bottom: 120px;
  left: 10px;
  border: 1px solid black;
  padding: 10px;
  transform: rotate(-90deg);
  cursor: pointer;
  &:hover {
    background-color: black;
    border-color: beige;
    color: beige;
  }
}

// Canvas

.canvas-container {
  width: 80vw;
  height: 70vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: beige;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  canvas {
    background-color: beige;
    border: 1px solid black;
  }
}

// Icons

.icon {
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  position: absolute;
  cursor: pointer;
  padding: 8px;
  background-color: beige;
  svg {
    fill: black;
  }
  &:hover {
    background-color: black;
    svg {
      fill: beige;
    }
  }
}

.close-canvas {
  top: 0;
  right: 0;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

.menu-icons {
  top: 0;
  left: 0;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

.clear-canvas {
  bottom: 0;
  left: 0;
  border-right: 1px solid black;
  border-top: 1px solid black;
}

.download-canvas {
  bottom: 0;
  right: 0;
  border-left: 1px solid black;
  border-top: 1px solid black;
}

// Canvas menu

.menu-container {
  z-index: 15;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 40px;
  background-color: beige;

  > div {
    display: flex;
    align-items: center;
    gap: 30px;
    height: 40px;
    width: 250px;
    margin: 0 auto;
    input,
    svg {
      cursor: pointer;
    }
    h4 {
      width: 100px;
      font-size: 0.8rem;
    }
    input[type="color"] {
      width: 20px;
      height: 20px;
    }
    input[type="range"] {
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;
    }
    input[type="range"]::-webkit-slider-runnable-track {
      background: beige;
      height: 16px;
      border: 1px solid black;
      border-radius: 8px;
      padding: 3px;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: black;
      border-radius: 50px;
      height: 8px;
      width: 8px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .open-canvas {
    display: none;
  }
}
