.card {
  &-container {
    text-align: center;
    border: 1px solid black;
    width: 420px;
  }
  &-image {
    cursor: pointer;
    height: 420px;
    width: 420px;
    position: relative;
    overflow: hidden;
    &:hover {
      img {
        transform: scale(1.02);
      }
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.02);
      }
    }
    .play-pause {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 5rem;
      color: beige;
      opacity: 0.8;
    }
  }
  &-text {
    line-height: 20px;
    padding: 15px;
    text-transform: uppercase;
    background-color: beige;
    border-top: 1px solid black;
    .card-genre {
      display: flex;
      justify-content: center;
      gap: 5px;
      margin-top: 10px;
      flex-wrap: wrap;
    }
    .genre-item {
      border: 1px solid black !important;
      font-weight: 200;
      padding: 1px 10px;
      font-size: 0.6rem;
      cursor: pointer;
      background-color: beige;
      color: black;
      &:hover {
        background-color: black;
        color: beige;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    &-container {
      height: 100%;
      width: 50%;
      border: 0px;
    }
    &-image {
      width: 100%;
      height: 280px;
      .play-pause {
        font-size: 65px;
        opacity: 0.5;
      }
    }
    &-text {
      display: none;
    }
  }

  .genre-item {
    border: 0px !important;
  }
}
