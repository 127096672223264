.music-player-container {
  border-top: 1px solid black;
  position: fixed;
  background-color: beige;
  height: 8vh;
  width: 100vw;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
  font-size: 1.2rem;
  text-transform: uppercase;
  p {
    font-size: 1rem;
  }
  .audio-player {
    display: none;
  }

  .title {
    width: 350px;
    padding-left: 20px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-start;
    cursor: pointer;
  }

  .progress {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .progress-control {
      width: 30vw;
    }

    .time-duration {
      width: 50px;
      text-align: center;
    }
  }

  .volume {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-right: 20px;
  }

  input[type="range"] {
    -webkit-appearance: none;
    background: transparent;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: beige;
    height: 16px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 3px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: black;
    border-radius: 50px;
    height: 8px;
    width: 8px;
  }
}

@media only screen and (max-width: 1200px) {
  .music-player-container {
    height: 8vh;
    font-size: 2.5rem;
    padding-left: 5vw;
    padding-right: 5vw;
    .title {
      width: 90%;
      order: 2;
      text-align: left;
      padding-left: 20px;
      padding-right: 60px;
    }
    .volume {
      display: none;
    }
    .progress {
      display: none;
    }
    .buttons {
      order: 1;
      width: 10%;
      .skip {
        display: none;
      }
    }
  }
}
